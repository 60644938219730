<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-09 10:57:59
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-01-28 10:40:22
-->

<template>
  <el-card class="box">
    <el-button type="primary" size="small" @click="onEdit()" round icon="plus"
      >新增</el-button
    >
  </el-card>
  <el-card class="box">
    <el-table :data="tableData.data">
      <el-table-column label="编号" prop="id" width="80"></el-table-column>
      <el-table-column label="LOGO" width="120">
        <template #default="scope">
          <el-avatar shape="square" size="large" :src="scope.row.logo_pic" />
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="name" width="200"></el-table-column>
      <el-table-column label="状态" prop="status_text" width="100">
        <template #default="scope">
          <el-tag
            :type="
              scope.row.status == 0
                ? 'success'
                : scope.row.status == 1
                ? 'warning'
                : 'error'
            "
            >{{ scope.row.status_text }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        label="联系人"
        prop="contact"
        width="100"
      ></el-table-column>
      <el-table-column
        label="电话"
        prop="mobile_phone"
        width="140"
      ></el-table-column>

      <el-table-column label="管理员" prop="status_text" width="200">
        <template #default="scope">
          <el-form>
            <el-form-item label="姓名:">
              {{ scope.row.master.chs_name }}
            </el-form-item>
            <el-form-item label="手机:">
              {{ scope.row.master.mobile_phone }}
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="钱包账号" prop="status_text" width="200">
        <template #default="scope">
          <el-form>
            <el-form-item label="余额:">
              ￥{{ scope.row.account.balance }}
            </el-form-item>
            <el-form-item label="状态:">
              <el-tag
                :type="scope.row.account.is_wrong ? 'error' : 'success'"
                >{{ scope.row.account.is_wrong ? "警告" : "正常" }}</el-tag
              >
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column
        label="创建时间"
        prop="create_at"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" fixed="right" width="150">
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onEdit(scope.row)"
            >修改</el-button
          >
          <!-- <el-popconfirm title="去定要删除？" @confirm="onDelete(scope.row)">
          <template #reference>
            <el-button type="text" size="small" class="text-danger"
              >删除</el-button
            >
          </template>
        </el-popconfirm> -->
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <edit-business :item="current" :reload="loadData"></edit-business>
</template>
<script>
import EditBusiness from "./edit_business.vue";
export default {
  components: {
    EditBusiness,
  },
  data() {
    return {
      loading: false,
      current: null,
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      tableData: {
        counts: 0,
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.current = null;
      this.$http.post("/admin/v1/business", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
    /**
     * 新增、修改
     */
    onEdit(item) {
      this.current = item
        ? item
        : {
            status: 0,
          };
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http.get("/admin/v1/app/del?appkey=" + item.app_key).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        }
      });
    },
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
  },
};
</script>

<style>
.el-table__body .el-form-item {
  margin-bottom: 0;
}
</style>