<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-09 10:58:10
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-01-28 11:14:33
-->


<template>
  <el-drawer
    v-model="drawer"
    :title="title"
    direction="rtl"
    size="800px"
    :show-close="false"
    :close-on-click-modal="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="名称" prop="name">
        <el-col :span="14">
          <el-input v-model="form.name" placeholder="请输入商户名称"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="联系人" prop="contact">
        <el-col :span="10">
          <el-input v-model="form.contact" placeholder="联系人姓名"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="手机号码" prop="mobile_phone">
        <el-col :span="10">
          <el-input
            v-model="form.mobile_phone"
            placeholder="联系人手机号码"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="状态">
        <el-radio-group v-model="form.status">
          <el-radio
            :label="item.value"
            :key="item.value"
            v-for="item in statusList"
            >{{ item.text }}</el-radio
          >
        </el-radio-group>
      </el-form-item>


      <el-form-item>
        <el-button type="primary" @click="onSubmit">确定</el-button>
        <!-- <el-button @click="cancelClick">取消</el-button> -->
      </el-form-item>
    </el-form>
  </el-drawer>
</template>
<script>
import common_api from "@/http/common_api";
export default {
  data() {
    return {
      drawer: false,
      title: "编辑商户",
      statusList: [],
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入商户名称",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["item", "reload"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.drawer = true;
          this.form = Object.assign({}, this.item);
          delete this.form.master;
          if (!this.item.id) {
            this.form.master = {};
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    /**
     * 获取状态参数列表
     */
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.statusList = res.data.business_status;
      }
    });
  },
  methods: {
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/admin/v1/business/edit", this.form)
            .then((res) => {
              this.loading = false;
              if (res.code == 0) {
                this.drawer = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                if (this.reload) {
                  this.reload();
                }
              }
            })
            .cath(() => {
              this.loading = false;
            });
        }
      });
    },
    /**
     * 取消
     */
    cancelClick() {
      this.drawer = false;
      if (this.reload) {
        this.reload();
      }
    },
  },
};
</script>